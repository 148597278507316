import React, { useState, useEffect } from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function ServiceWorkerNotification() {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then(registration => {
            registration.onupdatefound = () => {
              const installingWorker = registration.installing
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    setUpdateAvailable(true)
                    setOpen(true)
                  }
                }
              }
            }
          })
          .catch(error => {
            console.error('Error during service worker registration:', error)
          })
      })
    }
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div className="App">
      {updateAvailable && (
        <Snackbar open={open} autoHideDuration={60000} onClose={handleClose}>
          <Alert
            variant="standard"
            fullWidth
            onClose={handleClose}
            severity="warning"
          >
            New content is available. To apply the changes, please close all
            tabs for this page. For PWA apps on mobile
            devices, you may need to restart the app.
          </Alert>
        </Snackbar>
      )}
    </div>
  )
}

export default ServiceWorkerNotification
