import { Navigate } from 'react-router-dom'
import { useSelector } from 'src/redux/store'

function CityZipGuard({ children }) {
  const { pageContext, agencyInfo, appFlowType, leadType } = useSelector(
    state => state.app
  )

  if (pageContext?.cityZip?.zipCode && agencyInfo && appFlowType && leadType) {
    return children
  }
  return <Navigate to="/" replace />
}
export default CityZipGuard
