import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { APPLICATION_REDUX_LOCAL_STORAGE_NAME } from 'src/config-global'
import { authReducer } from '../features/auth/slices'
import { appReducer } from 'src/redux/slices/app-slice'

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: APPLICATION_REDUX_LOCAL_STORAGE_NAME,
  whitelist: [],
}

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: APPLICATION_REDUX_LOCAL_STORAGE_NAME,
  whitelist: [
    'isAuthenticated',
    'jwtToken',
    'userType',
    'fullName',
    'firstName',
    'lastName',
  ],
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  app: appReducer,
})

export default rootReducer
