import { Suspense, lazy } from 'react'

const Loadable = Component => props => (
  <Suspense fallback={'Loading...'}>
    <Component {...props} />
  </Suspense>
)

// Common Pages
export const CommonPages = {
  Login: Loadable(lazy(() => import('../pages/login'))),
  Register: Loadable(lazy(() => import('../pages/register'))),
  App: Loadable(lazy(() => import('../pages/app'))),
}

// Caregiver Pages
export const CaregiverPages = {
  Info: Loadable(lazy(() => import('../pages/caregiver/info'))),
  Benefits: Loadable(lazy(() => import('../pages/caregiver/benefits'))),
  CityZipSelection: Loadable(
    lazy(() => import('../pages/caregiver/city-zip-selection'))
  ),
  QuestionAnswer: Loadable(
    lazy(() => import('../pages/caregiver/question-answer'))
  ),
  Experience: Loadable(lazy(() => import('../pages/caregiver/experience'))),
  ShiftTiming: Loadable(lazy(() => import('../pages/caregiver/shift-timing'))),
  PersonalInfo: Loadable(
    lazy(() => import('../pages/caregiver/personal-info'))
  ),
  BookAppointment: Loadable(
    lazy(() => import('../pages/caregiver/book-appointment'))
  ),
  DateAndTime: Loadable(lazy(() => import('../pages/caregiver/date-and-time'))),
  AvailableTimeSlots: Loadable(
    lazy(() => import('../pages/caregiver/available-time-slots'))
  ),
  AppointmentDetails: Loadable(
    lazy(() => import('../pages/caregiver/appointment-details'))
  ),
  ThankYou: Loadable(lazy(() => import('../pages/caregiver/thank-you'))),
}

// Provider Pages
export const ProviderPages = {
  // Login: Loadable(lazy(() => import('../pages/login'))),
  // Welcome: Loadable(lazy(() => import('../pages/provider/welcome'))),
  CityZipSelection: Loadable(
    lazy(() => import('../pages/provider/city-zip-selection'))
  ),
  CareType: Loadable(lazy(() => import('../pages/provider/care-type'))),
  ContactInfo: Loadable(lazy(() => import('../pages/provider/contact-info'))),
  UserNotes: Loadable(lazy(() => import('../pages/provider/user-notes'))),
  DateAndTime: Loadable(lazy(() => import('../pages/provider/date-and-time'))),
  AvailableTimeSlots: Loadable(
    lazy(() => import('../pages/provider/available-time-slots'))
  ),
  AppointmentDetails: Loadable(
    lazy(() => import('../pages/provider/appointment-details'))
  ),
  BookAppointment: Loadable(
    lazy(() => import('../pages/provider/book-appointment'))
  ),
  ThankYou: Loadable(lazy(() => import('../pages/provider/thank-you'))),
}

// Care Pages
export const CarePages = {
  CityZipSelection: Loadable(
    lazy(() => import('../pages/care/city-zip-selection'))
  ),
  CareType: Loadable(lazy(() => import('../pages/care/care-type'))),
  ContactInfo: Loadable(lazy(() => import('../pages/care/contact-info'))),
  UserNotes: Loadable(lazy(() => import('../pages/care/user-notes'))),
  DateAndTime: Loadable(lazy(() => import('../pages/care/date-and-time'))),
  AvailableTimeSlots: Loadable(
    lazy(() => import('../pages/care/available-time-slots'))
  ),
  AppointmentDetails: Loadable(
    lazy(() => import('../pages/care/appointment-details'))
  ),
  BookAppointment: Loadable(
    lazy(() => import('../pages/care/book-appointment'))
  ),
  ThankYou: Loadable(lazy(() => import('../pages/care/thank-you'))),
}
