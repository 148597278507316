import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Iconify from 'src/components/iconify'
import { useSelector, dispatch } from 'src/redux/store'
import { setPageContext } from 'src/redux/slices/app-slice'
import { useRouter } from 'src/routes/hooks'

export default function NextPrevSubmitButton() {
  const {
    isButtonLoading,
    previousButtonProps,
    nextButtonProps,
    submitButtonProps,
    showSubmit,
    showNext,
    showPrevious,
    disableSubmit,
    disableNext,
    disablePrevious,
    keepPageContext,
  } = useSelector(state => state.app)
  const router = useRouter()
  const nextLink = nextButtonProps?.to

  const handleNext = React.useCallback(() => {
    if (keepPageContext) {
      dispatch(setPageContext())
    }
    router.push(nextLink)
  }, [keepPageContext, nextLink, router])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width={1}
    >
      {showPrevious && (
        <LoadingButton
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="mdi:arrow-left" />}
          component={RouterLink}
          to="/"
          loading={isButtonLoading}
          disabled={disablePrevious}
          {...previousButtonProps}
        >
          Previous
        </LoadingButton>
      )}
      {showSubmit && (
        <LoadingButton
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="mdi:check" />}
          component={RouterLink}
          to="/"
          loading={isButtonLoading}
          disabled={disableSubmit}
          {...submitButtonProps}
        >
          Submit
        </LoadingButton>
      )}
      {showNext && (
        <LoadingButton
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          endIcon={<Iconify icon="mdi:arrow-right" />}
          // component={RouterLink}
          // to="/"
          loading={isButtonLoading}
          disabled={disableNext}
          {...nextButtonProps}
          onClick={() => handleNext()}
        >
          Next
        </LoadingButton>
      )}
    </Box>
  )
}
