export function paramCase(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
}

export function snakeCase(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '')
}

export function startCase(str) {
  return str.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase()
  })
}

export function lowerCase(str) {
  return str.toLowerCase()
}
