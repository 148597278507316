import { Route, Routes } from 'react-router-dom'
import { ProviderPages } from './elements'
import { PROVIDER_PATHS } from './provider-paths'
import CityZipGuard from 'src/features/guard/city-zip-guard'
import AppFlowGuard from 'src/features/guard/app-flow-guard'

export function ProviderRoutes() {
  return (
    <Routes>
      {/* <Route path={PROVIDER_PATHS.login} element={<ProviderPages.Login />} />
      <Route
        path={PROVIDER_PATHS.welcome}
        element={<ProviderPages.Welcome />}
      /> */}
      <Route
        path={PROVIDER_PATHS.cityZipSelection}
        element={
          <AppFlowGuard>
            <ProviderPages.CityZipSelection />
          </AppFlowGuard>
        }
        index
      />
      <Route
        path={PROVIDER_PATHS.careType}
        element={
          <CityZipGuard>
            <ProviderPages.CareType />
          </CityZipGuard>
        }
      />
      <Route
        path={PROVIDER_PATHS.contactInfo}
        element={
          <CityZipGuard>
            <ProviderPages.ContactInfo />
          </CityZipGuard>
        }
      />
      <Route
        path={PROVIDER_PATHS.userNotes}
        element={
          <CityZipGuard>
            <ProviderPages.UserNotes />
          </CityZipGuard>
        }
      />
      <Route
        path={PROVIDER_PATHS.bookAppointment}
        element={
          <CityZipGuard>
            <ProviderPages.BookAppointment />
          </CityZipGuard>
        }
      />
      <Route
        path={PROVIDER_PATHS.dateAndTime}
        element={
          <CityZipGuard>
            <ProviderPages.DateAndTime />
          </CityZipGuard>
        }
      />
      <Route
        path={PROVIDER_PATHS.availableTimeSlots}
        element={
          <CityZipGuard>
            <ProviderPages.AvailableTimeSlots />
          </CityZipGuard>
        }
      />
      <Route
        path={PROVIDER_PATHS.appointmentDetails}
        element={
          <CityZipGuard>
            <ProviderPages.AppointmentDetails />
          </CityZipGuard>
        }
      />
      <Route
        path={PROVIDER_PATHS.thankYou}
        element={<ProviderPages.ThankYou />}
      />
    </Routes>
  )
}
