import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from 'notistack'

import ThemeProvider from './theme'
import Router from './routes/routes'
import { store, persistor } from './redux/store'
import { ServiceWorkerNotification } from './features/app-notification'
import { isIOS } from './utils/device-and-browser-detection'

function App() {
  const router = Router()

  // Add ios-device class to body for ios devices
  if (isIOS()) {
    document.body.classList.add('ios-device')
  }

  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading="Loading..." persistor={persistor}>
          <ThemeProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
              </LocalizationProvider>
            </SnackbarProvider>
            <ServiceWorkerNotification />
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  )
}

export default App
