// import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import AppHeader from './header'
import AppFooter from './footer'
import * as Styled from './styles'
import { useLocation } from 'react-router-dom'
import { PATH_PAGE } from 'src/routes/paths'

export default function AppLayout() {
  const location = useLocation()

  const shouldDisplayBGImage =
    location.pathname !== PATH_PAGE.care.thankYou &&
    location.pathname !== PATH_PAGE.caregiver.thankYou &&
    location.pathname !== PATH_PAGE.provider.thankYou

  const props = {
    showBgImg: !shouldDisplayBGImage,
  }

  return (
    <Styled.MainAppContainer props={props}>
      <AppHeader />
      <Outlet />
      <AppFooter />
    </Styled.MainAppContainer>
  )
}
