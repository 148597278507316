import { styled, alpha } from '@mui/material/styles'

import { bgGradient } from '../../utils/css-styles'
import overlay_2 from '../../assets/background/overlay-2.jpg'

export const StyledRoot = styled('main')(() => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  position: 'relative',
}))

export const StyledSectionBg = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  position: 'relative',
  '&:before': {
    content: "''",
    ...bgGradient({
      color: alpha(
        theme.palette.background.default,
        theme.palette.mode === 'light' ? 0.9 : 0.94
      ),
      imgUrl: overlay_2,
    }),
    top: 0,
    left: 0,
    zIndex: -1,
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    transform: 'scaleX(-1)',
  },
}))
