const ROOTS_CARE = '/care'

export const CARE_PATHS = {
  root: ROOTS_CARE,
  cityZipSelection: 'city-zip-selection',
  careType: 'care-type',
  contactInfo: 'contact-info',
  userNotes: 'user-notes',
  bookAppointment: 'book-appointment',
  dateAndTime: 'date-and-time',
  availableTimeSlots: 'available-time-slots',
  appointmentDetails: 'appointment-details',
  thankYou: 'thank-you',
}
