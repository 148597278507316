export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export function isAndroid() {
  return /Android/.test(navigator.userAgent)
}

export function isSafari() {
  return (
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor)
  )
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export function isBrowser() {
  return typeof window !== 'undefined'
}

export function isIE() {
  return (
    typeof window !== 'undefined' && window.document.documentMode !== undefined
  )
}

export function isEdge() {
  return typeof window !== 'undefined' && /Edge/.test(navigator.userAgent)
}

export function isChrome() {
  return (
    typeof window !== 'undefined' &&
    /Chrome/.test(navigator.userAgent) &&
    /Google Inc/.test(navigator.vendor)
  )
}

export function isFirefox() {
  return typeof window !== 'undefined' && /Firefox/.test(navigator.userAgent)
}

export function isOpera() {
  return typeof window !== 'undefined' && /OPR/.test(navigator.userAgent)
}

export function isChromium() {
  return typeof window !== 'undefined' && /Chromium/.test(navigator.userAgent)
}

export function isMac() {
  return typeof window !== 'undefined' && /Mac/.test(navigator.platform)
}

export function isWindows() {
  return typeof window !== 'undefined' && /Win/.test(navigator.platform)
}

export function isLinux() {
  return typeof window !== 'undefined' && /Linux/.test(navigator.platform)
}

export function isBrowserTabFocused() {
  return !document.hidden
}

export function isMobileSafari() {
  return isMobile() && isSafari()
}

export function isMobileDevice() {
  return isMobile() || isIOS()
}

export function isDesktop() {
  return !isMobileDevice()
}

export function isMobileChrome() {
  return isMobile() && isChrome()
}

export function isMobileFirefox() {
  return isMobile() && isFirefox()
}

export function isMobileOpera() {
  return isMobile() && isOpera()
}

export function isMobileChromium() {
  return isMobile() && isChromium()
}

export function isMobileEdge() {
  return isMobile() && isEdge()
}

export function isMobileIE() {
  return isMobile() && isIE()
}

export function isMobileWindows() {
  return isMobile() && isWindows()
}

export function isMobileMac() {
  return isMobile() && isMac()
}

export function isMobileLinux() {
  return isMobile() && isLinux()
}

export function isDesktopSafari() {
  return isDesktop() && isSafari()
}

export function isDesktopChrome() {
  return isDesktop() && isChrome()
}

export function isDesktopFirefox() {
  return isDesktop() && isFirefox()
}

export function isDesktopOpera() {
  return isDesktop() && isOpera()
}

export function isDesktopChromium() {
  return isDesktop() && isChromium()
}

export function isDesktopEdge() {
  return isDesktop() && isEdge()
}

export function isDesktopIE() {
  return isDesktop() && isIE()
}

export function isDesktopWindows() {
  return isDesktop() && isWindows()
}

export function isDesktopMac() {
  return isDesktop() && isMac()
}
