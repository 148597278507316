import { Route, Routes } from 'react-router-dom'
import { CaregiverPages } from './elements'
import { CAREGIVER_PATHS } from './caregiver-paths'
import CityZipGuard from 'src/features/guard/city-zip-guard'
import AppFlowGuard from 'src/features/guard/app-flow-guard'

export function CaregiverRoutes() {
  return (
    <Routes>
      <Route
        path={CAREGIVER_PATHS.info}
        element={
          <AppFlowGuard>
            <CaregiverPages.Info />
          </AppFlowGuard>
        }
        index
      />
      <Route
        path={CAREGIVER_PATHS.benefits}
        element={
          <AppFlowGuard>
            <CaregiverPages.Benefits />
          </AppFlowGuard>
        }
        index
      />
      <Route
        path={CAREGIVER_PATHS.cityZipSelection}
        element={
          <AppFlowGuard>
            <CaregiverPages.CityZipSelection />
          </AppFlowGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.questionAnswer}
        element={
          <CityZipGuard>
            <CaregiverPages.QuestionAnswer />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.experience}
        element={
          <CityZipGuard>
            <CaregiverPages.Experience />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.shiftTiming}
        element={
          <CityZipGuard>
            <CaregiverPages.ShiftTiming />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.personalInfo}
        element={
          <CityZipGuard>
            <CaregiverPages.PersonalInfo />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.bookAppointment}
        element={
          <CityZipGuard>
            <CaregiverPages.BookAppointment />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.dateAndTime}
        element={
          <CityZipGuard>
            <CaregiverPages.DateAndTime />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.availableTimeSlots}
        element={
          <CityZipGuard>
            <CaregiverPages.AvailableTimeSlots />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.appointmentDetails}
        element={
          <CityZipGuard>
            <CaregiverPages.AppointmentDetails />
          </CityZipGuard>
        }
      />
      <Route
        path={CAREGIVER_PATHS.thankYou}
        element={<CaregiverPages.ThankYou />}
      />
    </Routes>
  )
}
