import { Navigate } from 'react-router-dom'
import { useSelector } from 'src/redux/store'

function AppFlowGuard({ children }) {
  const { appFlowType, leadType } = useSelector(state => state.app)

  if (appFlowType && leadType) {
    return children
  }
  return <Navigate to="/" replace />
}
export default AppFlowGuard
