const ROOTS_PROVIDER = '/provider'

export const PROVIDER_PATHS = {
  root: ROOTS_PROVIDER,
  // login: 'login',
  // welcome: 'welcome',
  cityZipSelection: 'city-zip-selection',
  careType: 'care-type',
  contactInfo: 'contact-info',
  userNotes: 'user-notes',
  bookAppointment: 'book-appointment',
  dateAndTime: 'date-and-time',
  availableTimeSlots: 'available-time-slots',
  appointmentDetails: 'appointment-details',
  thankYou: 'thank-you',
}
