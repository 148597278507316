import * as React from 'react'

import { dispatch } from 'src/redux/store'
import { setCom100ScriptRef } from 'src/redux/slices/app-slice'

const MAX_ANCHOR_CHECK_ATTEMPTS = 10
const MAX_COM100_SITE_LOADED_CHECK_ATTEMPTS = 10
const ANCHOR_CHECK_INTERVAL = 3000
const COMM100_SITE_LOADED_CHECK_INTERVAL = 3000

// Get the anchor element for the chat button
export function getAnchor() {
  const anchor =
    document.querySelector('a[aria-label="Chat button, agent online"]') ||
    document.querySelector('a[aria-label="Open Live Chat window"]')

  // document.querySelector('a[aria-label="Chat button, agent offline"]')
  // document.querySelector('a:contains("Leave a message")')

  return anchor
}

// Check if the anchor is loaded and add the class to the parent element
function anchorFound(anchor) {
  console.log('Anchor found!')
  anchor.classList.add('chat-link--button')
  anchor.innerHTML = ''
  const parentElement = anchor.parentNode
  parentElement.classList.add('chat-container')
}

// Handle the case when the anchor is not found after maximum attempts
function anchorNotFound() {
  // Handle the case when the anchor is not found after maximum attempts
  console.log('Anchor not found with maximum attempts!')
}

// Check if the site is loaded and then check if the anchor is loaded
function checkAnchorIsLoaded() {
  console.log('Checking for anchor...')
  const anchor = getAnchor()
  if (!anchor) {
    let attempts = 0
    const interval = setInterval(() => {
      attempts++
      console.log('Checking for anchor...', attempts)
      const newAnchor = getAnchor()
      if (newAnchor) {
        clearInterval(interval)
        anchorFound(newAnchor)
      } else if (attempts >= MAX_ANCHOR_CHECK_ATTEMPTS) {
        clearInterval(interval)
        anchorNotFound()
      }
    }, ANCHOR_CHECK_INTERVAL)
  } else {
    anchorFound(anchor)
  }
}

// Check if the site is loaded and then check if the anchor is loaded
function checkSiteLoaded(t, e) {
  console.log('Checking for site loaded...')
  if (!t.loaded) {
    e('https://standby.comm100vue.com/livechat.ashx?siteId=')
    let attempts = 0
    const interval = setInterval(() => {
      attempts++
      console.log('Checking for site loaded...', attempts)
      checkSiteLoaded(t, e)
      if (attempts >= MAX_COM100_SITE_LOADED_CHECK_ATTEMPTS) {
        clearInterval(interval)
        console.log('Site not loaded after maximum attempts!')
      }
    }, COMM100_SITE_LOADED_CHECK_INTERVAL)
  } else {
    console.log('Site loaded!')
    checkAnchorIsLoaded()
  }
}

// LiveChat component Start =======================================================
function LiveChat(props) {
  const { codePlan, uuid } = props

  // If codePlan or uuid is not available, return null
  if (!codePlan && !uuid) {
    return null // or some fallback UI
  }

  React.useEffect(() => {
    if (!codePlan || !uuid) {
      const script = document.querySelector(
        'script[src*="comm100.com/livechat.ashx?siteId=1000547"]'
      )
      if (script) {
        console.log('Removing script...')
        console.log(script)
        script.remove()
      }

      // const comm100Elements = document.querySelectorAll(
      //   '[id^="comm100-iframe"]'
      // )
      // comm100Elements.forEach(element => {
      //   element.remove()
      // })
      return
    }
    var cap8UUID = uuid
    window.cap8UUID = cap8UUID
    var Comm100API = window.Comm100API || {}
    window.Comm100API = Comm100API
    ;(function (t) {
      function e(e) {
        var a = document.createElement('script'),
          c = document.getElementsByTagName('script')[0]
        dispatch(setCom100ScriptRef(a)) // Save the script reference
        a.type = 'text/javascript'
        a.async = true
        a.src = e + t.site_id
        c.parentNode.insertBefore(a, c)
      }
      t.chat_buttons = t.chat_buttons || []
      t.chat_buttons.push({
        code_plan: codePlan,
        div_id: `comm100-button-${codePlan}`,
      })
      t.site_id = 1000547
      t.main_code_plan = codePlan
      e('https://vue.comm100.com/livechat.ashx?siteId=')

      setTimeout(function () {
        checkSiteLoaded(t, e)
      }, COMM100_SITE_LOADED_CHECK_INTERVAL)
    })(Comm100API, cap8UUID)
  }, [codePlan, uuid])

  return <div id={`comm100-button-${codePlan}`}></div>
}

export default LiveChat
// LiveChat component Ends =======================================================
