import { Route, Routes } from 'react-router-dom'
import { CarePages } from './elements'
import CityZipGuard from 'src/features/guard/city-zip-guard'
import AppFlowGuard from 'src/features/guard/app-flow-guard'
import { CARE_PATHS } from './care-paths'

export function CareRoutes() {
  return (
    <Routes>
      <Route
        path={CARE_PATHS.cityZipSelection}
        element={
          <AppFlowGuard>
            <CarePages.CityZipSelection />
          </AppFlowGuard>
        }
        index
      />
      <Route
        path={CARE_PATHS.careType}
        element={
          <CityZipGuard>
            <CarePages.CareType />
          </CityZipGuard>
        }
      />
      <Route
        path={CARE_PATHS.contactInfo}
        element={
          <CityZipGuard>
            <CarePages.ContactInfo />
          </CityZipGuard>
        }
      />
      <Route
        path={CARE_PATHS.userNotes}
        element={
          <CityZipGuard>
            <CarePages.UserNotes />
          </CityZipGuard>
        }
      />
      <Route
        path={CARE_PATHS.bookAppointment}
        element={
          <CityZipGuard>
            <CarePages.BookAppointment />
          </CityZipGuard>
        }
      />
      <Route
        path={CARE_PATHS.dateAndTime}
        element={
          <CityZipGuard>
            <CarePages.DateAndTime />
          </CityZipGuard>
        }
      />
      <Route
        path={CARE_PATHS.availableTimeSlots}
        element={
          <CityZipGuard>
            <CarePages.AvailableTimeSlots />
          </CityZipGuard>
        }
      />
      <Route
        path={CARE_PATHS.appointmentDetails}
        element={
          <CityZipGuard>
            <CarePages.AppointmentDetails />
          </CityZipGuard>
        }
      />
      <Route path={CARE_PATHS.thankYou} element={<CarePages.ThankYou />} />
    </Routes>
  )
}
