import * as Styled from 'src/layouts/app/styles'
import NextPrevSubmitButton from 'src/components/next-prev-submit-buttons'

function AppFooter(props) {
  return (
    <Styled.StyledBox>
      <NextPrevSubmitButton />
    </Styled.StyledBox>
  )
}

export default AppFooter
