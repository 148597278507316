import { PROVIDER_PATHS } from './provider-paths'
import { CARE_PATHS } from './care-paths'
import { CAREGIVER_PATHS } from './caregiver-paths'

function path(...args) {
  return args.join('')
}

const ROOTS_AUTH = '/auth'
const ROOTS = '/seniors-helping-seniors'

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
}

export const PATH_COMMON_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_PAGE = {
  general: {
    root: ROOTS,
    app: path(ROOTS, '/app'),
  },
  care: {
    root: CARE_PATHS.root,
    cityZipSelection: path(
      ROOTS,
      CARE_PATHS.root,
      '/',
      CARE_PATHS.cityZipSelection
    ),
    careType: path(ROOTS, CARE_PATHS.root, '/', CARE_PATHS.careType),
    contactInfo: path(ROOTS, CARE_PATHS.root, '/', CARE_PATHS.contactInfo),
    userNotes: path(ROOTS, CARE_PATHS.root, '/', CARE_PATHS.userNotes),
    bookAppointment: path(
      ROOTS,
      CARE_PATHS.root,
      '/',
      CARE_PATHS.bookAppointment
    ),
    dateAndTime: path(ROOTS, CARE_PATHS.root, '/', CARE_PATHS.dateAndTime),
    availableTimeSlots: path(
      ROOTS,
      CARE_PATHS.root,
      '/',
      CARE_PATHS.availableTimeSlots
    ),
    appointmentDetails: path(
      ROOTS,
      CARE_PATHS.root,
      '/',
      CARE_PATHS.appointmentDetails
    ),
    thankYou: path(ROOTS, CARE_PATHS.root, '/', CARE_PATHS.thankYou),
  },
  caregiver: {
    root: CAREGIVER_PATHS.root,
    info: path(ROOTS, CAREGIVER_PATHS.root, '/', CAREGIVER_PATHS.info),
    benefits: path(ROOTS, CAREGIVER_PATHS.root, '/', CAREGIVER_PATHS.benefits),
    testimonial: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.testimonial
    ),
    cityZipSelection: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.cityZipSelection
    ),
    questionAnswer: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.questionAnswer
    ),
    experience: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.experience
    ),
    shiftTiming: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.shiftTiming
    ),
    personalInfo: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.personalInfo
    ),
    bookAppointment: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.bookAppointment
    ),
    dateAndTime: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.dateAndTime
    ),
    availableTimeSlots: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.availableTimeSlots
    ),
    appointmentDetails: path(
      ROOTS,
      CAREGIVER_PATHS.root,
      '/',
      CAREGIVER_PATHS.appointmentDetails
    ),
    thankYou: path(ROOTS, CAREGIVER_PATHS.root, '/', CAREGIVER_PATHS.thankYou),
  },
  provider: {
    root: PROVIDER_PATHS.root,
    // login: path(ROOTS, PROVIDER_PATHS.root, '/', PROVIDER_PATHS.login),
    // welcome: path(ROOTS, PROVIDER_PATHS.root, '/', PROVIDER_PATHS.welcome),

    cityZipSelection: path(
      ROOTS,
      PROVIDER_PATHS.root,
      '/',
      PROVIDER_PATHS.cityZipSelection
    ),
    careType: path(ROOTS, PROVIDER_PATHS.root, '/', PROVIDER_PATHS.careType),
    contactInfo: path(
      ROOTS,
      PROVIDER_PATHS.root,
      '/',
      PROVIDER_PATHS.contactInfo
    ),
    userNotes: path(ROOTS, PROVIDER_PATHS.root, '/', PROVIDER_PATHS.userNotes),
    bookAppointment: path(
      ROOTS,
      PROVIDER_PATHS.root,
      '/',
      PROVIDER_PATHS.bookAppointment
    ),
    dateAndTime: path(
      ROOTS,
      PROVIDER_PATHS.root,
      '/',
      PROVIDER_PATHS.dateAndTime
    ),
    availableTimeSlots: path(
      ROOTS,
      PROVIDER_PATHS.root,
      '/',
      PROVIDER_PATHS.availableTimeSlots
    ),
    appointmentDetails: path(
      ROOTS,
      PROVIDER_PATHS.root,
      '/',
      PROVIDER_PATHS.appointmentDetails
    ),
    thankYou: path(ROOTS, PROVIDER_PATHS.root, '/', PROVIDER_PATHS.thankYou),
  },
}

export const PAGE_VIEW = {
  agencyInfo: 'AGENCY_INFO',
  agencyList: 'AGENCY_LIST',
}
