import { createSlice } from '@reduxjs/toolkit'
import { LEAD_TYPE, WEEK_DAYS } from 'src/config-global'
import { _reduce, _sortBy } from 'src/utils/app-dash-utility'

function getDisabledDays(availableTimeSlots) {
  const disabledDays = []
  WEEK_DAYS.forEach((day, index) => {
    const dayAvailable = availableTimeSlots.find(item => item.dayOfWeek === day)
    if (!dayAvailable) {
      disabledDays.push(index)
    }
  })
  return disabledDays
}

const initialState = {
  isButtonLoading: false,
  previousButtonProps: null,
  nextButtonProps: null,
  submitButtonProps: null,
  showSubmit: false,
  showNext: false,
  showPrevious: false,
  disableSubmit: false,
  disableNext: false,
  disablePrevious: false,
  pageContext: null,
  currentPageContext: null,
  agencyInfo: null,
  keepPageContext: false,
  leadType: null,
  appFlowType: null,
  com100ScriptRef: null,
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startButtonLoading(state) {
      state.isButtonLoading = true
    },
    stopButtonLoading(state) {
      state.isButtonLoading = false
    },
    setPreviousButtonProps(state, action) {
      state.previousButtonProps = action.payload
    },
    setNextButtonProps(state, action) {
      state.nextButtonProps = action.payload
    },
    setSubmitButtonProps(state, action) {
      state.submitButtonProps = action.payload
    },
    setShowSubmit(state, action) {
      state.showSubmit = action.payload
    },
    setShowNext(state, action) {
      state.showNext = action.payload
    },
    setShowPrevious(state, action) {
      state.showPrevious = action.payload
    },
    setDisableSubmit(state, action) {
      state.disableSubmit = action.payload
    },
    setDisableNext(state, action) {
      state.disableNext = action.payload
    },
    setDisablePrevious(state, action) {
      state.disablePrevious = action.payload
    },
    setAgencyInfo(state, action) {
      const agencyInfo = action.payload

      const serviceProvided = _sortBy(
        agencyInfo?.service_provided?.service_provided
      )

      const availableTimeSlots = {
        [LEAD_TYPE.CLIENT]: _reduce(
          agencyInfo?.agency_preferences?.clientBookingPreferences,
          (acc, item) => {
            if (item.slotsAvailable) {
              acc.push(item)
            }
            return acc
          },
          []
        ),
        [LEAD_TYPE.EMPLOYEE]: _reduce(
          agencyInfo?.agency_preferences?.employeeInterviewBookingPreferences,
          (acc, item) => {
            if (item.slotsAvailable) {
              acc.push(item)
            }
            return acc
          },
          []
        ),
      }

      const disabledDays = {
        [LEAD_TYPE.CLIENT]: getDisabledDays(
          availableTimeSlots[LEAD_TYPE.CLIENT]
        ),
        [LEAD_TYPE.EMPLOYEE]: getDisabledDays(
          availableTimeSlots[LEAD_TYPE.EMPLOYEE]
        ),
      }

      state.agencyInfo = {
        ...agencyInfo,
        availableTimeSlots: availableTimeSlots[state.leadType],
        disabledDays: disabledDays[state.leadType],
        serviceProvided,
      }
    },
    clearAgencyInfo(state) {
      state.agencyInfo = initialState.agencyInfo
    },
    setPageContext(state) {
      const updatedPageContext = JSON.parse(
        JSON.stringify(state.currentPageContext)
      )
      state.pageContext = { ...state.pageContext, ...updatedPageContext }
      state.currentPageContext = initialState.currentPageContext
    },
    clearPageContext(state) {
      state.pageContext = initialState.pageContext
    },
    setCurrentPageContext(state, action) {
      state.currentPageContext = {
        ...state.currentPageContext,
        ...action.payload,
      }
    },
    clearCurrentPageContext(state) {
      state.currentPageContext = initialState.currentPageContext
    },
    enablePageContext(state) {
      state.keepPageContext = true
    },
    disablePageContext(state) {
      state.keepPageContext = false
    },
    setLeadType(state, action) {
      state.leadType = action.payload
    },
    clearLeadType(state) {
      state.leadType = initialState.leadType
    },
    setAppFlowType(state, action) {
      state.appFlowType = action.payload
    },
    clearAppFlowType(state) {
      state.appFlowType = initialState.appFlowType
    },
    setCom100ScriptRef(state, action) {
      state.com100ScriptRef = action.payload
    },
    clearCom100ScriptRef(state) {
      state.com100ScriptRef = initialState.com100ScriptRef
    },
  },
})

export const appReducer = slice.reducer

export const {
  startButtonLoading,
  stopButtonLoading,
  setPreviousButtonProps,
  setNextButtonProps,
  setSubmitButtonProps,
  setShowSubmit,
  setShowNext,
  setShowPrevious,
  setDisableSubmit,
  setDisableNext,
  setDisablePrevious,
  setAgencyInfo,
  clearAgencyInfo,
  setPageContext,
  clearPageContext,
  setCurrentPageContext,
  clearCurrentPageContext,
  enablePageContext,
  disablePageContext,
  setLeadType,
  clearLeadType,
  setAppFlowType,
  clearAppFlowType,
  setCom100ScriptRef,
  clearCom100ScriptRef,
} = slice.actions
