import { createBrowserRouter, Navigate } from 'react-router-dom'
import { CommonPages } from './elements'
import { AppLayout } from '../layouts'
import { PATH_PAGE } from './paths'
import { ProviderRoutes } from './provider-routes'
import { CareRoutes } from './care-routes'
import { CaregiverRoutes } from './caregiver-routes'

const COMMON_ROUTES = [{ element: <CommonPages.App />, index: true }]

export default function MainRoutes() {
  return createBrowserRouter([
    {
      path: '/',
      element: <Navigate to={PATH_PAGE.general.root} replace />,
      index: true,
    },
    {
      path: PATH_PAGE.general.root,
      element: <AppLayout />,
      children: [
        ...COMMON_ROUTES,
        {
          path: `${PATH_PAGE.general.root}${PATH_PAGE.care.root}/*`,
          element: <CareRoutes />,
        },
        {
          path: `${PATH_PAGE.general.root}${PATH_PAGE.caregiver.root}/*`,
          element: <CaregiverRoutes />,
        },
        {
          path: `${PATH_PAGE.general.root}${PATH_PAGE.provider.root}/*`,
          element: <ProviderRoutes />,
        },
      ],
    },
  ])
}
